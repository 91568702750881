#order-sample {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p.error {
        color: #d32f2f;
        font-size: 0.75rem;
        margin-bottom: 20px;
    }

    form {
        max-width: 436px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin: 36px 0;

        button {
            margin: 36px 0;
        }
    }
}