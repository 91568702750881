@import './components/assets/Felix.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', 'Roboto Condensed', 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #101010;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

#content {
  display: flex;
  flex-direction: column;
  max-width: 1360px;

  @media (max-width: 1360px) {
    padding: 0 20px;

  }
}

img {
  width: 100%;
  height: auto;
}

h1 {
  font-size: 92px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 60px;
  font-family: 'Roboto Condensed';
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 42px;
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 26px;
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

p {
  font-size: 32px;
  font-family: 'Inter';
  line-height: 150%;
  color: #101010;

  margin: 0;
  padding: 0;
}

.body1 {
  font-size: 32px;
  line-height: 150%;

  margin: 0;
  padding: 0;
}

.body2 {
  font-size: 28px;
  line-height: 150%;

  margin: 0;
  padding: 0;
}

.body3 {
  font-size: 20px;
  line-height: 150%;

  margin: 0;
  padding: 0;
}

@media (max-width: 960px) {
  h1 {
    font-size: 72px;
  }

  h2 {
    font-size: 48px;
  }

  h3 {
    font-size: 36px;
  }

  h4 {
    font-size: 24px;
  }

  p {
    font-size: 28px;
  }

  .body1 {
    font-size: 28px;
  }

  .body2 {
    font-size: 24px;
  }

}

@media (max-width: 600px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 24px;
  }

  .body1 {
    font-size: 24px;
  }

  .body2 {
    font-size: 20px;
  }

}

.mobile {
  display: none;
}

@media (max-width: 900px) {

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}