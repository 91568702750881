button {
    border: none;
    background: none;

    border-top: 2px solid #2b2b2b;
    border-bottom: 2px solid #2b2b2b;
    cursor: pointer;
    text-transform: uppercase;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
    width: max-content;
    height: fit-content;
    min-width: 230px;
    min-height: 66px;

    p {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 500;
    }

    svg {
        transition: transform 0.3s ease-in-out;
    }

    &:hover svg {
        transform: translateX(20px);
    }

    .spinner {

        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid #FFF;
        border-top: 2px solid #000;
        border-radius: 50%;
        animation: spin 1s linear infinite;


        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    @media (max-width: 600px) {

        min-height: 66px;
        gap: 20px;
        justify-content: space-between;
    }
}

footer {
    width: 100%;
    background-color: #1f1f21;
    color: #FFF;
    display: flex;
    justify-content: center;

    .content {
        width: 100%;
        max-width: 1360px;
        height: fit-content;

        a {
            color: #FFF;
            text-decoration: none;
            font-size: 18px;
            transition: 0.2s;

            &:hover {
                text-decoration: underline;
            }
        }

        .top {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 80px 0;


            .left {
                max-width: 436px;

                p.contact {
                    font-size: 18px;
                    margin-bottom: 20px;
                    color: #FFF;
                }

                button {
                    width: 100%;
                    color: #FFF;
                    justify-content: space-between;
                    text-transform: lowercase;
                    font-weight: 400;


                }

                .social {
                    margin-top: 32px;

                    img {
                        margin-right: 20px;
                        max-width: 30px;
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;

                .links {
                    display: flex;
                    justify-content: space-between;

                    .column {
                        display: flex;
                        flex-direction: column;

                        a {
                            margin-bottom: 20px;
                        }
                    }
                }

                img {
                    align-self: flex-end;
                    margin-top: 32px;
                    max-width: 100px;
                }
            }
        }

        .bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;

        }

        @media (max-width: 1360px) {
            padding: 0 20px;

        }

        @media (max-width: 960px) {
            .top {
                grid-template-columns: 1fr;
                margin-top: 40px;
                margin-bottom: 16px;

                .right {
                    margin-top: 16px;
                }

            }

            .bottom {
                flex-direction: column;
                align-items: center;

                a {
                    margin-bottom: 20px;
                }
            }
        }

        @media (max-width: 600px) {
            .top {
                .right {

                    img {
                        align-self: center;
                        margin-top: 32px;
                    }

                    .links {
                        flex-direction: column;
                        align-items: center;
                        text-align: center;

                        .column {
                            a {
                                margin-bottom: 32px;
                            }
                        }
                    }
                }

                .left {
                    max-width: 100%;
                }
            }

        }
    }
}

.notification {
    display: flex;
    position: fixed;
    top: 50px;
    left: 36%;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #FFF;

    &.success {
        background-color: #4CAF50;
    }

    &.failure {
        background-color: #F44336;
    }

    .close-button {
        margin-left: 32px;
        border: none;
        background: none;
        color: #FFF;
        font-size: 16px;
        min-width: auto;
        min-height: auto;
        cursor: pointer;
    }
}