#who {
    h1 {
        position: absolute;
        opacity: 0;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 250px;
        margin-top: 80px;

        .desktop {
            margin-top: 80px;
        }

        @media (max-width: 1370px) {
            h1 {
                font-size: 230px;
            }
        }

        @media (max-width: 1170px) {
            gap: 180px;
        }

        @media (max-width: 850px) {
            gap: 80px;
        }

        @media (max-width: 400px) {
            gap: 40px;

            img.header {
                margin-left: -40vw;
            }
        }

    }

    .mobile.text {
        p {
            margin-top: 20px;
        }
    }


    img.deco {
        max-width: 555px;
    }

    img.header {
        margin-left: -50vw;
        margin-top: 40px;
        width: 80vw;

        @media (min-width:1600px) {
            margin-left: -750px;
            width: 1300px;
        }
    }

    p {
        margin-top: 28px;
    }


}

#story {
    .desktop {
        margin-top: 180px;
        display: flex;
        align-items: flex-start;
        gap: 20px;

        h2 {
            font-size: 175px;
            line-height: 75%;

        }

        img {
            max-width: 440px;
            object-fit: contain;

            @media (max-width: 1300px) {
                display: none;
            }
        }

        @media (max-width: 900px) {
            display: none;
        }
    }

    .points {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 80px;

        .numbers {
            display: flex;
            align-items: center;
            justify-content: center;

            .number {
                font-size: 50px;
            }

            .label {
                font-size: 18px;
                margin-left: 10px;
                text-transform: uppercase;
            }
        }

        .top {
            display: grid;
            grid-template-columns: 2fr 1fr;

            .cont {
                width: 100%;
                display: flex;
                justify-content: center;

                p.body3 {
                    max-width: 320px;
                }
            }
        }

        .bottom {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            .cont {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                p.body2 {
                    max-width: 320px;
                }
            }
        }
    }

    .mobile.content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 80px;
        gap: 20px;

        .points {
            margin-top: 20px;
        }

        .numbers {
            justify-content: flex-start;
        }

        .number {
            font-size: 36px;
        }

        .label {
            font-size: 18px;
        }

        p.body3 {
            margin-top: 20px;
            font-size: 18px;
        }

        @media (max-width: 450px) {
            grid-template-columns: 1fr;
        }

        @media (min-width: 800px) {
            display: none;

        }
    }
}

#values {
    display: flex;
    margin-top: 180px;
    gap: 160px;

    h4 {
        font-weight: 500;
    }

    .text {
        .steps {
            margin-top: 80px;

            .step {
                padding: 20px 0;
                border-top: 1px solid #000;

                &:last-child {
                    border-bottom: 1px solid #000;
                }
            }
        }
    }

    .imgs {
        display: grid;
        grid-template-columns: 3fr 1fr;

        img {
            height: auto;
            object-fit: contain;

            &.people {
                align-self: flex-end;
            }

            &.bottle {
                align-self: flex-start;
            }
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;
        margin-top: 80px;
        gap: 80px;

        .text {
            .steps {
                margin-top: 40px;
            }
        }


        .imgs {
            img {
                &.bottle {
                    align-self: flex-end;
                }
            }
        }
    }
}

#why {
    margin-top: 180px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 160px;



    .text {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
            margin-bottom: 40px;
        }

        .item {
            display: flex;
            gap: 20px;

            &:last-child {
                p {
                    align-self: flex-end;
                }
            }
        }
    }

    @media (max-width: 1250px) {
        grid-template-columns: 1fr;

        img.deco {
            display: none;
        }
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        margin-top: 80px;
        gap: 40px;

        h2 {
            margin-bottom: 0;
        }

        .text {
            gap: 20px;


            .item {
                display: grid;
                grid-template-columns: 1fr 1fr;

                img {
                    object-fit: contain;
                }
            }
        }

        img.deco {
            display: block;
        }
    }


}

#mission {
    display: grid;
    margin-top: 180px;
    grid-template-columns: 1fr 1fr;
    gap: 160px;

    .text {
        .item {
            margin-top: 40px;

            p {
                margin-bottom: 20px;
            }
        }

    }

    .right {
        display: grid;
        grid-template-columns: 2fr 1fr;

        .item {
            p {
                margin-top: 20px;
            }
        }

        img.sep {
            align-self: flex-end;
        }
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        gap: 40px;
        margin-top: 80px;

        img.deco {
            display: none;
        }

    }
}

#join {
    margin-top: 180px;

    .content {
        margin-top: 80px;
        display: grid;
        grid-template-columns: 1fr 2fr;

        .form-container {
            display: flex;
            flex-direction: column;

            .text {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
            }

            form {
                display: flex;
                flex-direction: column;

                button {
                    align-self: flex-end;
                    margin: 40px 0;
                }
            }

        }
    }

    @media (max-width: 800px) {
        margin-top: 80px;

        .content {
            grid-template-columns: 1fr;
            margin-top: 40px;
        }
    }

    @media (max-width: 450px) {
        .content {
            .form-container {
                .text {
                    grid-template-columns: 1fr;
                }
            }
        }

    }
}