nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 32px;
    width: 100%;

    .links {
        ul {
            display: flex;
            list-style: none;
            gap: 4rem;

            li {
                margin-right: 1rem;

                a {
                    text-decoration: none;
                    color: #333;
                    text-transform: uppercase;
                    font-size: 18px;
                    transition: 0.2s;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    .hamburger {
        display: none;

        @media (max-width: 768px) {
            display: block;
            cursor: pointer;
        }
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    right: -100%; // Start off-screen to the right
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out; // Smooth transition

    &.open {
        right: 0; // Slide into view
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 4rem;

        li {
            padding: 1rem;
            border-bottom: 1px solid #ddd;

            a {
                text-decoration: none;
                color: #333;
                font-size: 1.2rem;
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }

    .cross {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        z-index: 1100; // Ensure it is above the mobile menu

        span {
            display: block;
            width: 100%;
            height: 0.25rem;
            background-color: #333;
            position: absolute;
            transition: transform 0.3s ease-in-out;

            &:first-child {
                transform: rotate(45deg);
            }

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
}