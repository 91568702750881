#hero {
    h1 {
        text-align: center;
        margin-top: 86px;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        gap: 95px;
        margin-top: 70px;
    }

    img.decor-desk {
        margin-top: 80px;
        width: 100%;
        height: auto;
    }

    img.decor {
        display: none;
    }

    @media (max-width: 900px) {
        h1 {
            text-align: left;
            margin-top: 40px;
        }

        .content {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            gap: 0;

            button {
                align-self: flex-end;
                margin-top: 24px;
            }
        }
    }

    @media (max-width: 600px) {
        img.decor-desk {
            display: none;
        }

        img.decor {
            margin-top: 40px;
            display: block;
            width: 100%;
            height: auto;
        }

    }
}

#solution {
    margin-top: 180px;

    @media (max-width: 900px) {
        margin-top: 80px;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 95px;

        .text {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            p {
                margin-bottom: 80px;
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
            gap: 0;

            .text {
                gap: 0;

                p {
                    margin: 32px 0;
                }
            }

            img {
                margin-top: 40px;
                max-width: 240px;
            }

        }

    }

    .nomore {
        margin-top: 180px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        p {
            font-family: 'Felix';
            font-size: 60px;
        }

        .nomore-head {
            margin-bottom: 10px;
        }

        .nomore-item {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .fade-in-out {
            animation: fadeInOut 3s infinite;
            height: 150px;
        }

        @keyframes fadeInOut {
            0% {
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @media (max-width: 900px) {
            margin-top: 80px;

            p {
                font-size: 40px;
            }


        }
    }
}

#bestsellers {
    margin-top: 180px;

    @media (max-width: 900px) {
        margin-top: 80px;
    }

    .headers {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;

        @media (max-width: 900px) {
            flex-direction: column;
            gap: 0;

        }
    }

    p.subheader {
        // margin-top: 40px;
    }

    .product {
        display: flex;
        gap: 120px;

        .text {

            p.description {
                max-width: 325px;
                margin: 32px 0;
            }
        }

        img.deco {
            width: 100%;
            height: auto;
        }

        &.gel {
            margin-top: 80px;


        }

        &.hard {
            margin-top: -360px;
            flex-direction: row-reverse;
            align-items: end;

            @media (max-width: 1280px) {
                margin-top: 40px;

            }
        }

        @media (max-width: 900px) {
            gap: 20px;

            p.description {
                font-size: 18px;
            }
        }
    }

    h2.offers {
        margin-top: 180px;

        @media (max-width: 900px) {
            margin-top: 80px;

        }
    }

    .services {
        margin-top: 80px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        .referral {
            max-width: 435px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
                margin: 32px 0;
            }

        }

        .bybp {
            .content {
                display: flex;

            }

            h3 {
                text-align: right;
            }

            img {
                width: 100%;
                height: auto;
            }

            .with-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 40px;


                p {
                    margin-top: 32px;
                }
            }

            .pic {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-right: 16px;
            }
        }

        @media (max-width: 900px) {
            gap: 20px;
            display: flex;
            flex-direction: column;

            p.description {
                font-size: 18px;
            }

            .referral {
                order: 2;

                img {
                    display: none;
                }

                button {
                    margin-top: 32px;
                }
            }

            .bybp {
                order: 1;

                .pic {
                    display: none;
                }
            }
        }
    }
}

#about {
    margin-top: 180px;



    .content {
        margin-top: 80px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 18px;

        img.deco {
            width: 100%;
            height: auto;

            &.cta {
                max-width: 320px;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                margin-top: 18px;
                font-size: 18px;

                &:first-child {
                    margin-top: 0;
                }
            }

            .slogan {
                p {

                    font-size: 42px;
                    font-family: 'Roboto Condensed', sans-serif;
                    text-transform: uppercase;
                }
            }

        }



        .cta {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            align-items: flex-end;
        }


    }



    @media (max-width: 900px) {
        margin-top: 80px;


        .content {
            grid-template-columns: 1fr;
            gap: 32px;
            margin-top: 40px;

            button {
                margin-top: 32px;
            }


        }

        .bottom {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;
            margin-top: 32px;

            .slogan {
                p {
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }

    }

    .benefits {
        margin-top: 180px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 18px;

        @media (max-width: 900px) {
            grid-template-columns: 1fr;
            margin-top: 80px;
            gap: 56px;

        }

        .benefit {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 900px) {}



            h4 {
                font-family: 'Felix';
                font-size: 32px;
                font-weight: 500;
                text-align: center;
            }

            ul {
                padding: 0;
                width: 100%;
            }

            li {
                list-style-type: none;
                font-family: 'Felix';
                margin-top: 18px;
                font-size: 24px;
                text-align: center;
                margin-right: 72px;


                &:nth-child(even) {
                    margin-right: 0;
                    margin-left: 72px;
                }
            }
        }
    }
}

#testimonials {
    margin-top: 180px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 32px;

    .review {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        display: flex;
        padding: 32px 0;

        h3 {
            margin-bottom: 16px;
        }

        .stars {
            img {
                max-width: 20px;
            }
        }

        .body {
            margin-left: 32px;

            .header {
                font-size: 26px;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 18px;


            }

            .pbody {
                font-size: 18px;
            }

        }
    }

    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        margin-top: 80px;

        .review {
            flex-direction: column;
            gap: 16px;

            .body {
                margin-left: 0;

                .header {
                    font-size: 22px;
                }
            }
        }

    }
}

#working {
    margin-top: 180px;

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;

        p.subheader {
            margin: 80px 0;
        }

        h3 {
            margin-top: 80px;
        }

        p.item {
            margin: 32px 0;
        }

        button {
            margin: 64px 0;
        }

        ul.steps {
            padding: 0;

            li {
                padding: 16px 0;
                border-bottom: 1px solid #000;
                display: flex;
                align-items: flex-start;

                &:first-child {
                    border-top: 1px solid #000;
                }

                list-style-type: none;

                p.number {
                    font-size: 22px;
                    font-weight: 500;
                }

                .content-list {
                    margin-left: 16px;
                }

                h4 {
                    font-size: 26px;
                    text-transform: uppercase;
                    font-weight: 400;
                }

                p.description {
                    font-size: 18px;
                    margin-top: 12px;
                }
            }
        }

        .imgs {
            display: flex;
            gap: 32px;
            margin: 32px 0;

            img {
                max-height: 287px;
                width: 100%;
                height: auto;
                object-fit: cover;

                &.hide {
                    @media (max-width: 1150px) {
                        display: none;

                    }
                }
            }
        }
    }

    .buildyourbrand {
        margin-top: 80px;

        h3 {
            margin: 0;
            margin-bottom: 80px;
        }
    }

    @media (max-width: 900px) {
        margin-top: 80px;

        .container {
            p.subheader {
                margin: 40px 0;
            }
        }

        .container {
            grid-template-columns: 1fr;

            .referral {
                img {
                    display: none;
                }
            }

            .left {
                order: 2;

                h3 {
                    margin-top: 40px;
                    margin-bottom: 32px;
                }
            }

            .right {
                .imgs {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;

                    img {
                        max-height: 200px;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

}

#faq {
    margin-top: 180px;

    .container {
        display: flex;
        justify-content: space-between;
        gap: 32px;

    }


    .left {
        img {
            max-width: 440px;
        }
    }

    .right {
        flex: 1;


        .subheader {
            font-size: 1.2em;
            color: #555;
            margin-bottom: 32px;
            margin-top: 16px;
        }

        .items {
            .item {
                cursor: pointer;
                margin-bottom: 10px;
                border-bottom: 1px solid #000;
                padding-bottom: 10px;

                &:first-child {
                    border-top: 1px solid #000;
                    padding-top: 10px;
                }

                .question-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    img {
                        max-width: 40px;
                    }
                }

                .question {
                    text-transform: uppercase;
                    font-family: 'Roboto Condensed', sans-serif;
                    font-size: 26px;
                    margin: 0;
                    padding: 16px 0;
                    transition: color 0.3s ease;
                    transition: font-weight 0.3s ease;

                    &:hover {
                        font-weight: 500;
                    }
                }

                .answer {
                    font-size: 18px;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.5s ease, padding 0.5s ease;

                }

                &.active .answer {
                    max-height: 200px;
                    /* Adjust based on content */
                    padding: 10px 10px;
                }
            }
        }
    }

    .left {
        flex: 1;
    }

    @media (max-width: 900px) {
        margin-top: 80px;

        .container {
            flex-direction: column;
        }

        .left {
            display: none;
        }

        .right {
            .items {
                .item {
                    .question-box {
                        img {
                            max-width: 20px;
                        }
                    }

                    .question {
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .answer {
                        font-size: 16px;
                    }
                }
            }
        }

    }
}

#contact {
    margin-top: 180px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    margin-bottom: 40px;

    .left {

        p.error {
            color: #d32f2f;
            font-size: 0.75rem;
            margin-top: 8px;
        }

        p.subheader {
            font-size: 32px;
            margin-top: 80px;
            margin-bottom: 64px;
        }

        form {
            display: flex;
            flex-direction: column;

            button {
                align-self: flex-end;
                margin-top: 80px;
            }
        }
    }

    .Mui-focused {
        border-bottom: #000 !important;
    }

    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        margin-top: 80px;

        .left {
            order: 2;
            margin-top: 40px;

            p.subheader {
                margin-top: 40px;
                margin-bottom: 32px;
                font-size: 24px;
            }
        }

        .right {
            img {
                max-height: 536px;
                object-fit: cover;
            }

        }

    }
}